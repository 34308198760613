<template>
  <div class="container">
    <van-tabs v-model="active">
      <van-tab title="派输注用户服务协议">
        <div class="agreement">
          <p class="bold">一、确认并接受</p>
          <p>
            1.本协议是您（亦称“用户”）与思派智慧科技（广州）有限公司（下称“思派智慧科技”或“我们”）之间关于使用“派输注”微信公众号平台（下称“派输注”或“本平台”）相关服务所订立的协议。
          </p>
          <p>
            2.您在使用本服务前应认真阅读、充分理解本协议条款。<span class="bold"
              >本协议构成法律上的书面协议，本协议限制、免责条款、与您的权益（可能）存在重大关系的条款，已采用加粗字体来特别提醒您，请您留意重点阅读</span
            >
            。除非您已阅读并接受本协议所有条款，否则您无权使用本服务。如您不同意本协议，或本协议全部或任何部分的修改，请您立即停止使用本服务。
          </p>
          <p>
            3.<span class="bold"
              >如果您未满18周岁，请务必在法定监护人监护、指导下阅读理解本协议，并特别注意未成年人使用条款。</span
            >
            您在进行注册、下单等任何行为或使用其他任何服务前，应事先征得您的法定监护人的同意。
          </p>
          <p>
            4.您使用本服务即视为您已阅读并同意接受本协议条款。根据国家法律法规变化及平台运营需要，思派智慧科技有权在必要时修改本协议条款，并公示于本平台。您可以在本平台的最新版本中查阅相关协议条款。 <span
              class="bold"
              >如您不同意更新后的新协议，您有权立即停止使用本服务，如您继续使用本服务，视作您已接受更新后的协议。</span
            >
          </p>
          <p>
            5.如果本协议中任何一条被司法或行政机关判定废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
          </p>
          <p>
            6.本服务如何收集、保护您的个人信息，请您阅读 《个人信息保护政策》予以进一步了解。
          </p>

          <!--  -->
          <p class="bold">二、账号注册与使用</p>
          <p>
            1.在您使用“派输注”提供相关服务前，需向“派输注”提供相关注册信息。您应遵循诚实信用、合法善意的原则，向“派输注”提交相关注册信息。相关注册信息应当遵守法律法规、国家利益、公民合法权益、公序良俗、信息真实等原则，不应提交任何违法或不良信息，相关信息如有变动，您应及时更新。 <span
              class="bold"
            >
              如果因您所提供的注册信息不合法、不真实、不准确或未及时更新，从而导致相关法律责任或不利后果的，您将承担相应的法律责任及不利后果。</span
            >
          </p>
          <p class="bold">
            2.您知悉并承诺，您具有完全民事权利能力和行为能力或虽不具有完全民事权利能力和行为能力但已经过您的家长和其他法定监护人同意并由您的家长和其他法定监护人代理注册及使用“派输注”的各项服务。若您不具备前述相适应的民事行为能力即进行账号注册的，则您及您的家长和其他法定监护人应依照法律规定承担因此而导致的一切后果。
          </p>
          <p>
            3.思派智慧科技非常重视对用户个人信息的保护。思派智慧科技将按照本协议及《个人信息保护政策》规定收集、使用、存储和分享您的个人信息，本协议对个人信息保护规定内容与《个人信息保护政策》相冲突的，及本协议对个人信息保护相关内容未作明确规定的，均应以《个人信息保护政策》的内容为准。思派智慧科技不会将您所提交的注册信息披露给任何非关联或非合作外的第三方，除非：
            <p>
            a)事先获得您的明确授权;</p>
            <p>
              b)“派输注”平台发生相关合并、分立、收购或资产转移；
            </p>
            <p>
            c)为提供相关服务所必需的；</p>
            <p>d)法律法规规定的其他情形。</p> 
          </p>
          <p class="bold">
            4.您应对您账号项下的所有行为（包括但不限于在线签署任何协议，浏览、上传、输入任何内容、下单预约等）负责，通过您的账号所发生的上述各项行为将视为您本人的真实意思表示。
          </p>
          <p class="bold">
            5.如“派输注”根据用户协议条款及相关规则中约定的违约认定程序及标准判断您账号的使用可能危及您的账号安全或“派输注”信息安全、正常交易秩序的，“派输注”可拒绝提供相应服务或终止本协议。
          </p>
          <p class="bold">
            6.您的下单行为应当基于真实需求，不得存在对服务实施恶意下单、恶意维权反复取消订单等扰乱正常交易秩序的行为。
          </p>

          <!-- 三、权利义务 -->
          <p class="bold">三、权利义务</p>
          <p>
            1.“派输注”为您提供包括但不限于输注预约等服务。<span class="bold"> 您理解并同意，鉴于网络服务的特殊性，思派智慧科技有可能新增、变更、中断或终止部分或全部服务，思派智慧科技将尽可能事先进行通知，并尽可能给您预留时间以便处理相关权益。</span>
          </p>
          <p class="bold">
            2.除特别说明外，“派输注”不断推出的新功能、新服务，均适用本协议之规范。如“派输注”中某一特定功能服务另有单独协议，则该单独协议为本协议之补充，您使用该特定服务即视为同意本协议及单独协议。
          </p>
          <p>
            3.“派输注”所提供信息，仅供参考之用。<span class="bold"> 您应对“派输注”中的信息自行加以判断，并承担因使用信息或依照信息所作行为而引起的所有风险，包括因对信息的正确性、完整性、实用性或个案适用性的依赖而产生的风险。</span>
            <p>
            a)您知晓并同意，“派输注”为独立的互联网平台，在“派输注”上展示的由第三方提供的服务相关信息（包括服务时间、服务地点等），均由服务机构提供。<span class="bold">如您在服务机构接受服务过程中，发生任何包括但不限于医疗事故、遭受第三方侵权或其他人身伤亡或财产损失的情形，抑或在输注后产生的任何不良反应等，您应与该服务机构对接处理或进行相应维权等。</span> </p>
            <p>
            b) <span class="bold"> 您知晓并同意，“派输注”所提供的输注预约等服务，不作为任何医学诊断、治疗推荐之依据，如用户出现有关症状，或需获得诊断或治疗，应尽早前往医院就医，并以专业医生诊断意见为准。</span>您在预约服务时，如您或实际使用人对自己是否适合相应的项目有疑虑的，请先咨询专业医师或相关人员或再决定。同时由于每个用户个人体质、身体情况不同，<span class="bold"> 请在实际接受服务前与医护人员或专业人员详细沟通个人身体健康情况以及疾病史过敏史等，并了解清楚相关项目的风险。</span></p>
            <p>
            c)您预约的输注等服务仅包含相关服务详情页描述的服务内容，若您或实际使用人到第三方服务机构线下场所后另行购买该服务机构的其他医疗相关服务或产品的，请确保您与相关医护人员或专业人员进行了详细沟通，并根据自身情况谨慎采购。</p>
            <p>
            d)您预约行为并不代表一定在您预期时间内够进行输注服务，请务必收到预约确认结果后再购买机票酒店等（如需要），以免造成损失。</p>
          </p>
          <p class="bold">
            4.“派输注”中可能存在为向用户提供便利而设置的外部链接，思派智慧科技将尽力要求第三方提供外部链接的准确性、完整性和安全性，但思派智慧科技无法保证第三方行为以及该等外部链接指向的不由“派输注”实际控制的任何网页上的内容，因此产生的相关责任由第三方承担。
          </p>
          <p class="bold">
            5.用户不得通过“派输注”发布、传播、传送任何违法违规、违反社会公德、侵犯他人权益、干扰本平台及服务正常运营或带有广告、营销信息、垃圾信息、骚扰信息、淫秽信息、虚假不实信息等的内容，包括但不限于：</p>
            <p>a)违反宪法确定的基本原则的；</p>
            <p>b)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
            <p>c)损害国家荣誉和利益的； </p>
            <p>d)煽动民族仇恨、民族歧视，破坏民族团结的；</p>
            <p>e)破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
            <p>f)散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
            <p>g)散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
            <p>h)侮辱或者诽谤他人，侵害他人合法权益的；</p>
            <p>i)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>
            <p>j)以非法民间组织名义活动的；</p>
            <p>k)不符合法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；</p>
            <p>l)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
            <p>m)涉及他人隐私、个人信息或资料的；</p>
            <p>n)含有软件病毒、蠕虫、木马或其他有害的计算机代码、文件、脚本、代理或程序的资料；</p>
            <p>o)利用“派输注”平台的用户账号进行牟利性经营活动（包括但不限于：提供代预约、代购、转让名额、转让优先接受服务资格等）；</p>
            <p>p)不得进行不当注册（包括通过各种方式批量注册账号）、恶意购买、恶意维权；</p>
            <p>q)不得利用“派输注”发布诈骗、虚假信息的。</p>
            <p>r)用户不得利用平台规则漏洞谋取任何不正当利用，包括但不限于重复刷单。</p>
            <p>s)其他根据法律法规及本条款约定禁止的信息内容。</p>
          

          
            <p class="bold">6.除非法律允许或思派智慧科技书面许可，您使用“派输注”过程中不得从事下列行为；</p>
            <p class="bold">a)对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；</p>
            <p class="bold">b)对思派智慧科技拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p>
            <p class="bold">c)对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经思派智慧科技授权的第三方工具/服务接入本软件和相关系统；</p>
            <p class="bold">d)通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>
            <p class="bold">e)通过非思派智慧科技开发、授权的第三方软件、插件、外挂、系统，登录或使用思派智慧科技软件及服务，或制作、发布、传播上述工具；</p>
            <p class="bold">f)自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；</p>
            <p class="bold">g)删除本软件上关于著作权的信息；</p>
            <p class="bold">h)其他未经思派智慧科技明示授权的行为；</p>
            <p class="bold">i)提交虚假信息，或冒充、利用他人名义的；</p>
            <p class="bold">j)诱导其他用户点击链接页面或分享信息的；</p>
            <p class="bold">k)虚构事实、隐瞒真相以误导、欺骗他人的；</p>
            <p class="bold">l)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
            <p class="bold">m)未经思派智慧科技书面许可利用“派输注”任何功能进行推广或互相推广的；</p>
            <p class="bold">n)制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</p>
            <p class="bold">o)其他违反法律法规规定侵犯其他用户合法权益、采用技术手段破坏公平交易、干扰产品正常运营或思派智慧科技未明示授权的行为、利用本软件及服务从事任何违法犯罪活动的。</p>
          
          <p class="bold">
            7.您理解并同意，思派智慧科技有权依合理判断对违反有关法律法规或本协议规定的行为进行处理，对违法违规的任何用户采取取消订单、拒绝提供服务并采取适当的法律措施，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任，包括但不限于因此导致第三方的损失、思派智慧科技的损失等。
          </p>
          <p class="bold">
            8.在法律允许的范围内，思派智慧科技对以下不可掌控的情形导致的服务无法正常使用及其引发的结果不承担责任：</p>
            <p class="bold">
            a)不可抗力（包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等）；</p>
            <p class="bold">
            b)受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
            <p class="bold">
            c)电脑软件、系统、硬件和通信线路出现故障；</p>
            <p class="bold">
            d)电信部门技术调整或故障；</p>
            <p class="bold">
            e)因系统维护、升级需要而造成的暂时中断，但思派智慧科技将尽力及时恢复，减少不利影响；</p>
            <p class="bold">
            f)因其他第三方原因导致的；</p>
            <p class="bold">
            g)用户自身的操作不当；</p>
            <p class="bold">
            h)由于网络信号不稳定、带宽小等原因，所引起的登录失败、页面打开速度慢等风险；</p>
            <p class="bold">
            i)其他思派智慧科技无法控制或合理预见的情形。</p>

          <p class="bold">四、知识产权</p>
          <p>
            思派智慧科技拥有“派输注”内所有内容（含平台本身）的知识产权或相关权益，所有这些内容受著作权、商标权、专利权和其它财产所有权法律的保护。任何人只有在获得思派智慧科技或所有权人的书面授权之后才能使用这些内容，而不能擅自复制、修改、制作衍生产品、进行还原工程、反向编译，或以其它方式转移任何权利，或用于任何商业目的。
          </p>

          <p class="bold">五、协议终止</p>
          <p class="bold">
            1.本服务协议于以下任一情形下终止：</p>
            <p class="bold">
            a)在您满足“派输注”的账号注销条件时，您通过“派输注”的注销流程自行注销，本服务协议于账号注销之日终止。您理解并同意，您已认真阅读 《平台账户注销须知》，并已了解、同意相关用户注销流程及注销后的权利义务安排；</p>
            <p class="bold">
            b)思派智慧科技有权根据自身商业安排经过合理的提前通知终止全部服务，本服务协议于“派输注”全部服务依法定程序及方式终止之日终止。</p>
          </p>
          <p class="bold">
            2.本协议终止后，思派智慧科技将无法继续向您提供任何服务或履行任何其他义务，包括但不限于为您保留或向您披露原服务账号中的任何信息。
          </p>
          <p>
            3.本服务协议的终止不影响第四条知识产权条款以及其他根据其内容应继续有效的条款的有效性，也不影响本服务协议终止前各方的相关权利和义务，包括但不限于守约方依据本服务协议向违约方追究相应的违约责任。
          </p>
          <p class="bold">六、通知与送达</p>

          <p>
            1.您知悉并认可，思派智慧科技可通过向您注册时所提交手机号码发送电子信息的方式，向您通知重要信息；前述电子信息在发送成功后即视为送达。
          </p>
          <p>
            2.您应当保证所提供的联系方式是准确、有效的，并进行适时更新，如因提供的联系方式不准确或怠于更新等不可归责于思派智慧科技的原因，导致相关通知无法送达或及时送达，您将自行承担由此可能产生的法律后果。
          </p>

          <p class="bold">七、其他</p>
          <p class="bold" >1.本协议签订地约定为广州市黄埔区。</p>
          <p>
            2.本协议的解释、效力及纠纷的解决，适用于中华人民共和国法律（不包括冲突法）。
          </p>
          <p>
            3.若您和思派智慧科技之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，<span class="bold">您在此完全同意将纠纷或争议提交本协议签订地有管辖权的法院管辖。</span> 
          </p>
          <p>
            4.本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约行为弃权。
          </p>
          <p class="bold">
            5.
            思派智慧科技未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。
          </p>
          <p>
            6.关于思派智慧科技
            <p>
            a)如您对本平台提供的相关服务存任何疑问、意见或建议，请致电：400-0523-990；</p>
            <p>
            a)当您与思派智慧科技联系时，<span class="bold"> 思派智慧科技可能会保存您的通信/通话记录和内容、联系方式等信息，</span>以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</p>
          </p>
        </div>
      </van-tab>
      <van-tab title="派输注个人信息保护政策">
        <div class="agreement">
          <p>
            “派输注”微信公众号平台（下称“派输注”或“本平台”）提供的服务是建立在与您彼此信任基础之上，为向您提供更优质的服务，本平台将收集您的部分信息，为了使您提供的所有信息都能得到保障，本平台采用下述关于个人信息保护政策：
          </p>
          <p>
            1.本平台收集的信息范围仅限于本平台认为对了解您的需求和开展业务所必需的相关资料，以便本平台为您提供全方位的产品或服务；
          </p>
          <p>2.本平台尽力确保对您的信息记录是准确和及时的；</p>
          <p>
            3.本平台设有严格的安全系统，以防止未经授权的任何人，包括本平台职员获取、泄露您的信息；
          </p>
          <p>
            4.任何第三方，在没有得到本平台许可获取您的信息时，都将被要求严格遵守保密责任。
          </p>
          <p>
            本平台一直致力于依法保护您的信息。鉴于此，本平台制定了本政策，旨在向您说明，本平台收集使用您的信息的范围和规则。<span
              class="bold"
              >请您在使用本平台提供的服务前，务必仔细阅读本政策，尤其是加黑加粗部分。
              您需充分理解并同意本政策，一旦您通过本平台开始注册账户、登录账户、浏览网页或使用本平台提供的服务，即表示您已充分理解并同意本政策。</span
            > 
          </p>
          <p>您可以根据目录索引阅读相应章节，进一步了解本政策的具体约定：</p>
          <p>一、我们如何收集和使用个人信息</p>
          <p>二、我们如何使用Cookie和同类技术</p>
          <p>三、我们如何存储和保护个人信息</p>
          <p>四、信息安全</p>
          <p>五、我们如何对外提供信息</p>
          <p>六、您的权利</p>
          <p>七、我们如何保护未成年人的信息</p>
          <p>八、对第三方责任的声明</p>
          <p>九、本政策的适用及更新</p>
          <p>十、联系我们</p>

          <p class="bold">一、我们如何收集和使用个人信息</p>
          <p>
            个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。在您使用“派输注”过程中，我们会按照如下范围和方式收集和使用您在使用服务时主动提供、授权提供或因为使用服务而产生的信息，用以向您提供服务及优化服务质量：
          </p>
          <p class="bold">1.业务功能：</p>
          <p>
            a)当您注册或登录“派输注”账号、使用“派输注”服务过程中，我们将收集您的以下个人信息：
            <span class="bold">
              手机号码 、位置信息、Mac地址、IP地址、姓名、年龄、个人用药需求、处方信息等；</span
            >
          </p>
          <p>
            b)当您使用拨打输注中心电话功能时，我们将访问您手机拨打电话权限，便于您直接向输注中心拨打电话。
          </p>
          <p>
            c)当您使用“派输注”满意度评价功能时，我们将读写您的手机存储/照片/视频、相机、麦克风(IOS)权限，并收集您的以下个人信息：评价的文字、图片、视频（若开通此项功能）。若评论中涉及他人/儿童/青少年个人信息的，请您谨慎评论并且在发布前分别征得该自然人/（儿童/青少年）监护人的同意。
          </p>

          <p>
            2.当您使用本服务与第三方系统/服务互通功能时，其他系统/服务的提供方在经您同意后可能获取您主动公开或传输的相关信息，但我们将进行充分评估并严格实行最小化提供，您在选择前应充分了解第三方系统/服务的产品功能及信息保护策略。
          </p>
          <p>
            3.如您拒绝我们调用相关权限，仅会使您无法使用上述功能，但不会影响到您正常使用“派输注”的其他功能（除非特别说明），基于您撤回授权前我们收集的上述信息的处理行为不会受到影响。
          </p>
          <p>4.我们收集的上述信息将被用于以下场景：</p>
          <p>a)实现前述内容中收集信息之目的；</p>
          <p>b)为您提供“派输注”其他相关服务；</p>
          <p>c)用于身份验证、安全防范、存档等用途，确保服务的安全性。</p>
          <p>
            5.根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的个人信息而无需征得您的授权同意：
          </p>
          <p>a)与个人信息控制者履行法律法规规定的义务相关的；</p>
          <p>b)与国家安全、国防安全直接相关的；</p>
          <p>c)与公共安全、公共卫生、重大公共利益直接相关的；</p>
          <p>d)与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
          <p>
            e)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
          </p>
          <p>f)所涉及的个人信息是您自行向社会公众公开的；</p>
          <p>g)根据您的要求签订和履行合同所必需的；</p>
          <p>
            h)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
          </p>
          <p>
            i)维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
            请您理解，我们向您提供的服务是不断更新和发展的。如我们基于服务所需收集、使用的信息超出前述说明范围的，我们会通过页面提示、修改协议约定的方式另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本政策以及“派输注”《用户服务协议》的相关约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，您将无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。
          </p>

          <p class="bold">二、我们如何使用Cookie和同类技术</p>
          <p>
            1.为使您获得更轻松的访问体验，您访问我们的服务或网站时，我们可能会通过小型数据文件识别您的身份，以帮您省去重复录入信息，快速判断您的账户安全状态。这些文件可能是Cookie，Cookie通常包含标识符、站点名称以及一些号码和字符。
          </p>
          <p>
            2.请您理解，我们的某些服务只能通过使用Cookie才可得到实现。我们不会将Cookie用于本隐私政策所述目的之外的任何用途。当退出登录后您的Cookie将会被从当前设备删除。
          </p>

          <p class="bold">三、我们如何存储和保护个人信息</p>
          <p>
            1.我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。
          </p>
          <p>
            2.
            <span class="bold">
              一般而言，我们仅为实现目的所必需的时间保留您的个人信息</span
            >
            ，除非法律有强制留存的要求。当我们的服务发生停止运营的情形时，我们将以推送公告等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。
          </p>
          <p>
            3.我们会采取合理的措施保护您及儿童/青少年的个人信息，以防止信息的丢失、泄露、损毁、非授权访问、非授权披露。我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。
          </p>
          <p class="bold">
            4.请您理解，由于技术限制以及风险防范的局限性，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您使用我们的服务的过程中，也有可能因我们可控范围外的情况而发生信息泄露问题。请您务必妥善保管好您的账号名及其他个人信息。您在使用本服务时，我们会通过您的账号来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，并可能对您产生其他不利影响。如您发现您的账号及/或其他个人信息可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低可能对您带来的不利影响。
          </p>

          <p class="bold">四、信息安全</p>
          <p>1.安全保护措施</p>
          <p>
            a)我们努力为用户的信息安全提供保障，以防止信息的泄露、丢失、不当使用、未经授权访问和披露等。我们使用多方位的安全保护措施，以确保用户的个人信息保护处于合理的安全水平，包括技术保护手段、管理制度控制等诸多方面。
          </p>
          <p>
            b)我们建立了数据安全管理制度和规范的管理流程，严格落实工作人员访问个人信息的最小够用授权原则。
          </p>
          <p>
            c)我们建立了保障个人信息安全专门的管理制度、流程和组织。我们也会审查该管理制度、流程和组织，以防未经授权的人员擅自访问、使用或披露用户的信息。
          </p>
          <p>
            d)我们建议您在使用产品和服务时充分注意对个人信息的保护，我们也会提供多种安全功能来协助您保护自己的个人信息安全。
          </p>
          <p>2.安全事件处置措施</p>
          <p>
            我们已制定个人信息安全事件应急预案和报告机制，定期组织内部相关人员进行演练和培训，使其掌握岗位职责和应急处置策略和规程。如不幸发生个人信息安全事件，我们将尽可能的采取阻止事件蔓延的措施，并将按照法律法规的要求，采取合理、有效的方式发布公告或向特定受影响用户告知。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
          </p>

          <p class="bold">五、我们如何对外提供信息</p>
          <p>1.我们在以下情况会向第三方共享您提供的个人信息：</p>
          <p class="bold">a)在获得您的明确同意的情况下共享；</p>
          <p class="bold">
            b)根据法律规定、有法律赋予权限的政府部门要求的情况下共享；
          </p>
          <p class="bold">
            c)只有对外提供您的相关信息，才能实现相关功能服务等。
          </p>
          <p>
            2.我们与我们的关联公司具备高度一致的数据安全保障措施和管理水平。为共同向您提供服务、履行监管要求、提高您使用服务的安全性、便利性的服务体验，基于您授权，我们会在关联公司间共享您的个人信息。在此种情况下，我们只会共享必要的个人信息，如我们的共享改变个人信息的使用及处理目的，将再次征求您的授权同意。
          </p>
          <p>3.涉及个人信息的第三方服务管理：</p>
          <p>
            a)为了完善产品功能或提升服务质量，我们可能会引入优质的第三方服务，在正式接入前，我们将严格落实评估第三方服务的准入条件，签订保密协议，我们将要求第三方按照协议约定、本隐私政策及其他任何相关的保密和安全措施来处理您的个人信息，保障落实用户权益。 <span
              class="bold"
              >您可以通过第三方平台提供的链接查看相关第三方的个人信息保护政策及处理个人信息的措施和方法，或者咨询第三方平台关于个人信息的相关权益。</span
            >
          </p>
          <p class="bold">
            b)您理解并同意，若您自主选择使用输注预约功能等服务的，我们将向您所选的输注中心提供您的姓名、年龄、手机号码、预约信息等为实现服务目的所必需的信息。
          </p>
          <p>
            4.随着我们业务的持续发展，我们有可能进行合并、收购、资产转让等交易，我们将开展个人信息安全影响评估，告知您相关情形，并按照法律法规及不低于本政策所要求的标准继续保护或要求新的控制者继续保护您的个人信息。
          </p>
          <p class="bold">
            5.我们不会对外公开披露收集的个人信息，如必须公开披露时，我们将开展个人信息安全影响评估，向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。但以下情况除外：
          </p>
          <p class="bold">
            a)根据相关法律法规及国家标准，在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息而无需征得您的同意：
          </p>
          <p class="bold">b)与个人信息控制者履行法律法规规定的义务相关的；</p>
          <p class="bold">c)与国家安全、国防安全直接相关的；</p>
          <p class="bold">d)与公共安全、公共卫生、重大公共利益直接相关的；</p>
          <p class="bold">e)与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
          <p class="bold">
            f)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
          </p>
          <p class="bold">g)个人信息主体自行向社会公众公开的个人信息；</p>
          <p class="bold">
            h)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
          </p>

          <p class="bold">六、您的权利</p>
          <p>
            您明确知晓并同意，在符合法定的情形下，您对您的信息拥有合法的查阅、更正、删除、改变授权同意的范围或撤回授权。与此同时，您行使上述权利不得与本平台需要遵循的其他义务相违背，也不得超出下列合法需求范围外。
          </p>
          <p>
            1.查阅和更正您的信息：您可以与本平台联系，核实本平台是否持有您的信息，并查阅您的信息。如本平台所持有的您的信息存在错误，您可以要求本平台更正您的信息。
          </p>
          <p>2.删除您的信息：在以下情形中，您可以向本平台提出删除信息的请求</p>
          <p>a)如果本平台违反法律法规规定，收集、使用您的信息的；</p>
          <p>b)本平台违反与您的约定，收集、使用您的信息的；</p>
          <p>
            c)您的信息的处理目的已实现、无法实现或者为实现处理目的不再必要；
          </p>
          <p>
            d)平台停止提供产品或者服务，或者保存期限已届满。
            本平台违反法律法规的规定或违反与您的约定，向第三方转让、共享您的信息、或公开披露您的信息
            的，如您要求删除，本平台应立即停止转让、共享、或披露行为，并通知第三方、或药房相关接收方
            删除相关信息。
          </p>

          <p>
            3.改变您授权同意的范围或撤回您的授权：您可以通过关闭功能设备、注销账户等方式撤回您的授权或改变授权同意的范围。请您理解，当您撤回同意或授权后，本平台无法为您提供撤回同意或授权所对应的产品或服务。您撤回同意或授权的决定，不会影响本平台此前基于您的授权而开展的个人信息处理。
          </p>
          <p>
            4.响应您的上述请求：在以下情形中，按照法律法规要求，本平台将无法响应您的请求
          </p>
          <p>a)与国家安全、国防安全有关的；</p>
          <p>b)与公共安全、公共卫生、重大公共利益有关的；</p>
          <p>c)与犯罪侦查、起诉、审判和执行判决等有关的；</p>
          <p>d)有充分证据表明信息主体存在主观恶意或滥用权利的；</p>
          <p>
            e)响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
          </p>
          <p>
            f)涉及商业秘密的。适用法律法规的要求，删除或匿名化处理您的个人信息。
          </p>

          <p class="bold">七、我们如何保护未成年人的信息</p>
          <p>
            1.我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定，如您是18周岁以下的未成年人，在使用本服务前，请建议您的监护人仔细阅读本隐私政策，并在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。在您注册和使用本服务时，我们默认您已得到您的监护人的同意，我们将根据国家相关法律法规及本隐私政策的规定保护未成年人的个人信息。
          </p>
          <p>
            2.我们仅会在取得儿童监护人明确同意的情况下收集儿童个人信息，并只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
          </p>

          <p class="bold">八、对第三方责任的声明</p>
          <p>
            当您同意通过“派输注”平台跳转至第三方服务平台，并接受第三方提供的产品或服务时，您理解并同意，相关产品或服务由该第三方自主运营自行负责，您在使用该第三方提供的产品或服务（包括您为使用目的向该第三方提供任何个人信息）时，受该第三方的用户协议及隐私保护政策约束，而非本政策。本政策仅适用于“派输注”平台所收集的相关信息，我们对任何第三方使用您向其所提供的相关信息的行为不承担任何责任。
          </p>

          <p class="bold">九、本政策的适用及更新</p>
          <p>
            1.本政策适用于“思派智慧科技（广州）有限公司”在“派输注”提供的所有服务。
          </p>
          <p>2.发生下列重大变化情形时，我们会适时对本政策进行更新：</p>
          <p>
            a)我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；
          </p>
          <p>b)收集、存储、使用个人信息的范围、目的、规则发生变化；</p>
          <p>c)对外提供个人信息的对象、范围、目的发生变化；</p>
          <p>d)您访问和管理个人信息的方式发生变化；</p>
          <p>e)数据安全能力、信息安全风险发生变化；</p>
          <p>
            f)用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；
          </p>
          <p>g)其他可能对您的个人信息权益产生重大影响的变化。</p>
          <p>
            3.如本政策发生更新，我们将以推送通知、弹窗提示等方式通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。 如您在本政策更新生效后继续使用本服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约。
          </p>
          <p>4.您可以在“派输注-个人中心”中查看本政策。</p>

          <p class="bold">十、联系我们</p>
          <p>1.任何与您信息有关的请求或投诉，请致电：400-0523-990；</p>
          <p>
            2.当您与思派智慧科技联系时，思派智慧科技可能会保存您的通信/通话记录和内容、联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
          </p>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: 'agreement',
  props: {
    active: {
      required: true,
      boolean: Number,
      default () {
        return 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .agreement {
    background: #fff;
    padding: 10px;
    text-align: left;
    font-size: 13px;
    .bold {
      font-weight: 700;
    }
  }
}
</style>